export class ThemeColor {

    readonly hex: string
    readonly r: number = 255
    readonly g: number = 255
    readonly b: number = 255

    constructor (hex: string) {
        this.hex = hex
        const rgbFromHex = hex.match(/^#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i)
        if (rgbFromHex) {
            const rgbComponents = rgbFromHex.slice(1).map(e => parseInt(e, 16))
            this.r = rgbComponents[0]
            this.g = rgbComponents[1]
            this.b = rgbComponents[2]
        }
    }
}
