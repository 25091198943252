<template>

    <Head>
        <Title>{{ title }}</Title>
        <Meta property="og:url" :content="url" />
        <Meta property="og:type" content="website" />
        <Meta property="og:locale" content="en_US" />
        <Meta name="twitter:card" content="summary_large_image" />
        <Meta name="title" :content="title" />
        <Meta property="og:title" :content="title" />
        <Meta name="twitter:title" :content="title" />
        <Meta name="description" :content="description" />
        <Meta name="keywords" :content="keywords" />
        <Meta property="og:description" :content="description" />
        <Meta name="twitter:description" :content="description" />
        <Meta property="og:image" :content="image" />
        <Meta name="twitter:image" :content="image" />
        <Meta name="twitter:site" :content="twitterCreator" />
        <Meta name="twitter:creator" :content="twitterCreator" />
        <Meta v-if="!isProduction" name="robots" content="noindex, nofollow" />
    </Head>
</template>
<script lang="ts" setup>
import type { CapabilitiesPageModel, CareerOpportunitiesPageModel, InsightPageModel, InteriorPageModel, LocationPageModel, PeoplePageModel, WebSpotlightRootModel } from '~/models'
import { contentTypes } from '~/models/project/contentTypes'
const config = useRuntimeConfig()
const pageStore = usePageStore()
const siteStore = useSiteStore()
const isProduction = config.public.isProduction
const title = ref<string>('')
const url = ref<string>('')
const twitterCreator = ref<string>(siteStore.siteConfig.elements.twitterCreator.value)
const description = ref<string>('')
const keywords = ref<string>(siteStore.siteConfig.elements.seoMetadataMetaKeywords.value)
const image = ref<string>('')
title.value = pageStore.pageTitle
if (pageStore.page) {
    switch (pageStore.page.system.type) {
        case contentTypes.web_spotlight_root.codename: {
            const model = pageStore.page as WebSpotlightRootModel
            description.value = model?.elements.seoMetadataMetaKeywords.value
            break
        }
        case contentTypes.interior_page.codename: {
            const model = pageStore.page as InteriorPageModel
            description.value = model?.elements.seoMetadataMetaKeywords.value
            break
        }
        case contentTypes.people_page.codename: {
            const model = pageStore.page as PeoplePageModel
            description.value = model?.elements.seoMetadataMetaKeywords.value
            break
        }
        case contentTypes.location_page.codename: {
            const model = pageStore.page as LocationPageModel
            description.value = model?.elements.seoMetadataMetaKeywords.value
            break
        }
        case contentTypes.insight_page.codename: {
            const model = pageStore.page as InsightPageModel
            description.value = model?.elements.seoMetadataMetaKeywords.value
            break
        }
        case contentTypes.capabilities_page.codename: {
            const model = pageStore.page as CapabilitiesPageModel
            description.value = model?.elements.seoMetadataMetaKeywords.value
            break
        }
        case contentTypes.career_opportunities_page.codename: {
            const model = pageStore.page as CareerOpportunitiesPageModel
            description.value = model?.elements.seoMetadataMetaKeywords.value
            break
        }
        default:
    }
}
</script>
